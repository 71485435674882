"use client"

import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import {Sheet,SheetClose,SheetContent,SheetDescription,SheetFooter,SheetHeader, SheetTitle,} from '@/components/ui/sheet'
import React, {useContext, useEffect} from "react";
import { SelectTrigger,
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectValue,
} from "@/components/ui/select";
import {Separator} from "@/components/ui/separator";
import { useAuth } from "@/app/db/Auth"

export function Debug() {
    const [open, setOpen] = React.useState<boolean>(false)
    const [online, setOnline] = React.useState<boolean>(true)
    const {user, team} = useAuth()
    
    useEffect(() => {
        const handler = (event: KeyboardEvent) => {
            if (event.metaKey && event.key === 'e') {
                setOpen(prevV=>!prevV);
            }
        };

        window.addEventListener('keydown', handler);

        return () => {
            window.removeEventListener('keydown', handler);
        };
        }, []);
    
    if (typeof window !== "undefined") {
        window.addEventListener('online', () => setOnline(true));
        window.addEventListener('offline', () => setOnline(false));
    }
    
    return (
        <Sheet open={open} onOpenChange={(e)=>{
            setOpen(e)
        }
}>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>Debug Menu</SheetTitle>
                    <SheetDescription>
                        This page shows diagnostic tools for developers.
                    </SheetDescription>
                </SheetHeader>
                <div className="grid gap-4 py-4">
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-left">
                            Team Name
                        </Label>
                        <Input id="name" disabled value={team?.name ?? "Not Logged In" } className="col-span-3" onChange={(e)=>console.log(e)} />
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="name" className="text-left">
                            Email
                        </Label>
                        <Input id="name" disabled value={user?.email ?? "Not Logged In" } className="col-span-3" onChange={(e)=>console.log(e)} />
                    </div>
                    <div className="hidden grid grid-cols-4 items-center gap-4">
                        <Label htmlFor="username" className="text-left">
                            Picture
                        </Label>
                        <Select>
                            <SelectTrigger className="w-[180px]">
                                <SelectValue placeholder="Select a Picture" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>Profile Picture</SelectLabel>
                                    <SelectItem value="bird">Bird</SelectItem>
                                    <SelectItem value="bunny">Bunny</SelectItem>
                                    <SelectItem value="whale">Whale</SelectItem>
                                    <SelectItem value="cow">Cow</SelectItem>
                                    <SelectItem value="pig">Pig</SelectItem>
                                </SelectGroup>
                            </SelectContent>
                        </Select>
                        
                        {/*<Input id="username" value="@peduarte" className="col-span-3" />*/}
                    </div>
                    <Separator/>
                    
                    <div>
                        <p className="text-sm text-muted-foreground mb-1">Internet Connection</p>
                        <Button disabled={true} type="submit" variant={!online ? "destructive" : "secondary"} className={`w-full`}> {online ? "Connected!" : "Disconnected!"}</Button>
                    </div>
                </div>
                <SheetFooter>
                    <SheetClose asChild>
                        <Button type="submit" className={"w-full"}>Save changes</Button>
                    </SheetClose>
                </SheetFooter>
            </SheetContent>
        </Sheet>
        )
}
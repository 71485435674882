"use client"

import { Session, User } from '@supabase/supabase-js';
import { useContext, useState, useEffect, createContext } from 'react';
import { supabase } from './clientSupabase';
import { Team } from './user';
import Cookies from "js-cookie";
import { usePathname } from 'next/navigation'

export const runtime = 'edge';
interface AuthContextType { 
    teams: Team[];
    team: Team | undefined;
    session: Session | null | undefined;
    user: User | null | undefined;
    signOut: () => void;
    setTeam: (team: Team) => void;
    refresh: () => void;
}

const getInitialState = (): AuthContextType => ({
    teams: [],
    team: undefined,
    session: null,
    user: null,
    signOut: () => {},
    setTeam: (team: Team) => {},
    refresh: () => {}
});

// create a context for authentication
const AuthContext = createContext<AuthContextType>(getInitialState());

export const AuthProvider = ({ children }: any) => {

    // don't render auth on index to save request bandwith
    const pathname = usePathname()
//    if (pathname == "/"){
//        return (
//        <>
//        <AuthContext.Provider value={undefined}>
//        {children}
//        </AuthContext.Provider>
//        </>
//    )}

    let teamId = Cookies.get("ppTeam")
    let lastRefresh = Cookies.get("ppLastRefresh");
    if (!lastRefresh) {
        Cookies.set("ppLastRefresh", String(Date.now()))
    }

    let personal = !teamId || teamId === "personal"
    
    const [user, setUser] = useState<User>()
    const [team, setTeam] = useState<Team>()
    const [teams,setTeams] = useState<Team[]>([])
    const [session, setSession] = useState<Session | null>();

    const setData = async () => {
        const { data: { session }, error } = await supabase.auth.getSession();
        if (error || !session) {
            setUser(undefined)
            setSession(undefined)
            setTeam(undefined)
            setTeams([])
            return
        }
        setSession(session)
        setUser(session?.user)
        supabase.auth.mfa.getAuthenticatorAssuranceLevel().then((e)=>{
            if (e.data?.currentLevel !== e.data?.nextLevel && pathname !== "/login") {
                window.location.href='/login'
            }
        })

    };

    const setTeamData = async () => {

        const {data, error} = await supabase.rpc('get_accounts');
        if (!error) {
            let teams: Team[] = data as Team[];
            setTeams(teams);
            

            if (personal) {
                setTeam(teams.filter((e)=>e.personal_account)[0]) // guarenteed
            } else {
                let potentialTeams = teams.filter((e)=>e.account_id==(teamId ?? ""))
                if (potentialTeams.length == 0) {
                    setTeam(teams.filter((e)=>e.personal_account)[0])
                } else {
                    setTeam(potentialTeams[0])
                }
            }
        }
    }

    useEffect(() => {

        const { data: listener } = supabase.auth.onAuthStateChange((_event, session) => {
            setSession(session);
            setUser(session?.user)
        });

        setData();
        setTeamData()

        return () => {
            listener?.subscription.unsubscribe();
        };
        }, []);

    const value = {
        team,
        teams,
        session,
        user,
        signOut: () => supabase.auth.signOut(),
        setTeam: async(team: Team) => {
            Cookies.set("ppTeam", team.account_id)
            await setTeamData()
            await setTeam(team)
        },
        refresh: async() => { // 10000 - 3000 == 7000
            let lastRefresh = Number.parseInt(Cookies.get("ppLastRefresh") ?? "0")
            if (!user || 10000>(Date.now()-lastRefresh)) return // prevent unnecessary double requests, user will always be up-to date here on full reload, and this will run if it's a navigate
            Cookies.set("ppLastRefresh", String(Date.now()))
            setTimeout(async()=>{
                await setData()
                await setTeamData()
                if (teams.length==0 && !user) { // impossible, unless deleted or invalid
                    await logout()
                }
            },300)
        }
    };

    // use a provider to pass down the value
    return (
        <AuthContext.Provider value={value}>
            {children}
    </AuthContext.Provider>
    );
};

// export the useAuth hook
export const useAuth = () => {
    return useContext(AuthContext);
};

export async function logout() {
    await supabase.auth.signOut()
// todo remove cookies
    window.location.href='/login';
}
"use client"

import * as React from "react"
import { ThemeProvider as NextThemesProvider } from "next-themes"
import { type ThemeProviderProps } from "next-themes/dist/types"
import { usePathname } from "next/navigation";


const ignoredPaths = [
    "/",
]

// @ts-ignore
export function ThemeProvider({ children, ...props }: ThemeProviderProps) {
    
    const pathname = usePathname();
    const forcedThemeFromPathname = (ignoredPaths.includes(pathname) && "light") ?? undefined
    
    // @ts-ignore
    return <NextThemesProvider forcedTheme={forcedThemeFromPathname} {...props}>{children}</NextThemesProvider>
}

"use client"

import '@/app/globals.css';
import React from 'react';
import { Toaster } from '@/components/ui/toaster';
import {AuthProvider} from "@/app/db/Auth";
import {Debug} from "@/lib/debug";
import Script from "next/script";
import { SpeedInsights } from '@vercel/speed-insights/next';
import { ThemeProvider } from '@/components/other/themeprovider';
import { Analytics } from "@vercel/analytics/react"

export default function Layout({
    children
}: {
    children: React.ReactNode
}) {

    return (
        <ThemeProvider
            attribute="class"
            defaultTheme="system"
            enableSystem
            disableTransitionOnChange
        >
            <AuthProvider>
                <Script src={"https://eu.umami.is/script.js"} data-website-id="a5149167-470a-46ad-a3a9-ca62d4d496c5" defer={true}/>
                <Debug/>
                {children}
                <SpeedInsights />
                <Analytics/>
                <Toaster />
            </AuthProvider>
        </ThemeProvider>
        )
}